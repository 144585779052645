import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  desks: [
    {
      id: 1,
      deskName: 'desk 1',
      numberDesk:'11',
      city:'Paris',
      location:'BNP Paribas...',
      username: 'kliger7',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      id: 2,
      deskName: 'desk 1',
      numberDesk:'11',
      city:'Paris',
      location:'BNP Paribas...',
      username: 'kliger7',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      id: 3,
      deskName: 'desk 1',
      numberDesk:'11',
      city:'Paris',
      location:'BNP Paribas...',
      username: 'kliger7',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      id: 4,
      deskName: 'desk 1',
      numberDesk:'11',
      city:'Paris',
      location:'BNP Paribas...',
      username: 'kliger7',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      id: 5,
      deskName: 'desk 1',
      numberDesk:'11',
      city:'Paris',
      location:'BNP Paribas...',
      username: 'kliger7',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      id: 6,
      deskName: 'desk 1',
      numberDesk:'11',
      city:'Paris',
      location:'BNP Paribas...',
      username: 'kliger7',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      id: 7,
      deskName: 'desk 1',
      numberDesk:'11',
      city:'Paris',
      location:'BNP Paribas...',
      username: 'kliger7',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      id: 8,
      deskName: 'desk 1',
      numberDesk:'11',
      city:'Paris',
      location:'BNP Paribas...',
      username: 'kliger7',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      id: 9,
      deskName: 'desk 1',
      numberDesk:'11',
      city:'Paris',
      location:'BNP Paribas...',
      username: 'kliger7',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      id: 10,
      deskName: 'desk 1',
      numberDesk:'11',
      city:'Paris',
      location:'BNP Paribas...',
      username: 'kliger7',
      avatar: require('@src/assets/images/avatars/9.png').default
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/desks/list/all-data').reply(200, data.desks)

// POST: Add new desk
mock.onPost('/apps/desks/add-desk').reply((config) => {
  // Get event from post data
  const desk = JSON.parse(config.data)
  const highestValue = data.desks.reduce((a, b) => (a.id > b.id ? a : b)).id

  desk.id = highestValue + 1

  data.desks.push(desk)

  return [201, { desk }]
})

// GET Updated DATA
mock.onGet('/api/desks/list/data').reply((config) => {
  const {
    q = '',
    page = 1,
    perPage = 10,
    sort = 'asc',
    status = null,
    services = null,
    sortColumn = 'fullName'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.desks.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    (desk) =>
      (desk.email.toLowerCase().includes(queryLowered) ||
        desk.department.toLowerCase().includes(queryLowered) ||
        desk.fullName.toLowerCase().includes(queryLowered) ||
        desk.phone.toLowerCase().includes(queryLowered)) &&
        desk.services === (services || desk.services) &&
        desk.status === (status || desk.status)
  )
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      desks: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET desk
mock.onGet('/api/desks/desk').reply((config) => {
  const { id } = config
  const desk = data.desks.find((i) => i.id === id)
  return [200, { desk }]
})

// DELETE: Deletes desk
mock.onDelete('/apps/desks/delete').reply((config) => {
  // Get desk id from URL
  let deskId = config.id

  // Convert Id to number
  deskId = Number(deskId)

  const deskIndex = data.desks.findIndex((t) => t.id === deskId)
  data.desks.splice(deskIndex, 1)

  return [200]
})

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { paginateArray } from '@src/@fake-db/utils'

export const getAllStaff = createAsyncThunk('appStaffs/getAllStaff', async () => {
  const response = await axios.get('/qadmin/staffs')
  return response.data
})


export const getAllDesks = createAsyncThunk('appStaffs/getAllDesks', async () => {
  const response = await axios.get('/qadmin/desks')
  return response.data
})

export const getData = createAsyncThunk('appStaffs/getData', async params => {
  const { q = '', page = 1, perPage = 10, sort = 'asc', sortColumn = 'name', allData = [] } = params

  // /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const filteredData = allData.filter(
    staff => staff.email.toLowerCase().includes(queryLowered) ||
      staff.name.toLowerCase().includes(queryLowered) ||
      staff.contact.toLowerCase().includes(queryLowered)
  )

  const dataAsc = filteredData.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const sendData = dataToFilter
  return {
    params,
    data: paginateArray(sendData, perPage, page),
    totalPages: sendData.length
  }
})

export const getStaff = createAsyncThunk('appStaffs/getStaff', async id => {
  const response = await axios.get(`/qadmin/staff/${id}`)
  return response.data
})

export const addStaff = createAsyncThunk('appStaffs/addStaff', async (staff, { dispatch, getState }) => {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  }
  await axios.post('/qadmin/staff', staff, config)
  await dispatch(getData(getState().staffs.params))
  await dispatch(getAllStaff())
  return staff
})

export const deleteStaff = createAsyncThunk('appStaffs/deleteStaff', async (id, { dispatch, getState }) => {
  await axios.delete(`/qadmin/staff/${id}`)
  await dispatch(getAllStaff())
  await dispatch(getData(getState()))
  return id
})  

export const updateStaff = createAsyncThunk('appStaffs/updateStaff', async ({id,staff}, { dispatch, getState }) => {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  }
  await axios.put(`/qadmin/staff/${id}`, staff, config)
  await dispatch(getAllStaff())
  await dispatch(getStaff(id))
  await dispatch(getData(getState()))
  return staff
})

export const appStaffsSlice = createSlice({
  name: 'appStaffs',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedStaff: null,
    departments: [],
    desks: [],
    services: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllStaff.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getStaff.fulfilled, (state, action) => {
        state.selectedStaff = action.payload
      })
      .addCase(getAllDesks.fulfilled, (state, action) => {
        state.desks = action.payload
      })
  }
})

export default appStaffsSlice.reducer

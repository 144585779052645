// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { paginateArray } from '@src/@fake-db/utils'
import { getAllStreams, getData as getDataStreams } from "../../streams/store"
export const getAllTVGroup = createAsyncThunk('appTVGroups/getAllTVGroup', async () => {
  const response = await axios.get('/group_list/details/')
  return response.data
})

export const getData = createAsyncThunk('appTVGroups/getData', async params => {
  const { q = '', page = 1, perPage = 10, sort = 'asc', sortColumn = 'group_name', allData = [] } = params

  // /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const filteredData = allData.filter(
    tvGroup => tvGroup.group_name.toLowerCase().includes(queryLowered)
  )

  const dataAsc = filteredData.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const sendData = dataToFilter
  return {
    params,
    data: paginateArray(sendData, perPage, page),
    totalPages: sendData.length
  }
})

export const getTVGroup = createAsyncThunk('appTVGroups/getTVGroup', async id => {
  const response = await axios.get(`/group/${id}`)
  return response.data
})

export const addTVGroup = createAsyncThunk('appTVGroups/addTVGroup', async (tvGroup, { dispatch, getState }) => {
  await axios.post('/group-create/', tvGroup)
  await dispatch(getAllTVGroup())
  await dispatch(getData(getState()))
  return tvGroup
})

export const deleteTVGroup = createAsyncThunk('appTVGroups/deleteTVGroup', async (id, { dispatch, getState }) => {
  await axios.delete(`/group-delete/${id}`)
  await dispatch(getAllTVGroup())
  await dispatch(getData(getState()))
  await dispatch(getDataStreams(getState()))
  await dispatch(getAllStreams())
  return id
})  

export const updatetvGroup = createAsyncThunk('appTVGroups/updatetvGroup', async (tvGroup, { dispatch, getState }) => {
  await axios.put(`/group/update/${tvGroup.id}`, tvGroup)
  await dispatch(getAllTVGroup())
  await dispatch(getTVGroup(tvGroup.id))
  await dispatch(getData(getState()))
  return tvGroup
})

export const appTVGroupsSlice = createSlice({
  name: 'appTVGroups',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedTVGroup: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllTVGroup.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getTVGroup.fulfilled, (state, action) => {
        state.selectedTVGroup = action.payload
      })
  }
})

export default appTVGroupsSlice.reducer


import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { useTranslation } from "react-i18next"

// ** Axios Imports
import axios from 'axios'
import { paginateArray } from '@src/@fake-db/utils'

export const getAllDesks = createAsyncThunk('appDesks/getAllDesks', async () => {
  const response = await axios.get('/qadmin/desks')
  return response.data
})

export const getData = createAsyncThunk('appDesk/getData', async (params) => {
  const { q = '', page = 1, perPage = 10, sort = 'asc', sortColumn = 'name', allData = [] } = params

  // /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const filteredData = allData.filter(
    desk => desk.name.toLowerCase().includes(queryLowered)
  )

  const dataAsc = filteredData.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const sendData = dataToFilter
  return {
    params,
    data: paginateArray(sendData, perPage, page),
    totalPages: sendData.length
  }
})

export const getDesk = createAsyncThunk('appDesk/getDesk', async (id) => {
  const response = await axios.get(`/qadmin/desk/${id}`)
  return response.data
})

export const getDeskt = createAsyncThunk('appDesk/getDeskt', async (id) => {
    const { t } = useTranslation()
    return t()
  })

export const addDesk = createAsyncThunk('appDesk/addDesk', async (desk, { dispatch, getState }) => {
  await axios.post('/qadmin/desk-add', desk).then(res=>toast.warning(res?.data?.details))
  .catch(error=>console.error(error))
  await dispatch(getData(getState().desks.params))
  await dispatch(getAllDesks())
  return desk
})

export const deleteDesk = createAsyncThunk('appDesk/deleteDesk', async (id, { dispatch, getState }) => {
  await axios.delete(`/qadmin/desk/${id}`)
  await dispatch(getData(getState().desks.params))
  await dispatch(getAllDesks())
  return id
})

export const updateDesk = createAsyncThunk('appDesk/updateDesk', async ({id, desk}, { dispatch, getState }) => {
  await axios.patch(`/qadmin/desk/${id}`, desk)
  await dispatch(getData(getState().desks.params))
  await dispatch(getDesk(id))
  await dispatch(getAllDesks())
  return desk
})

export const getAllLocation = createAsyncThunk('appLocations/getAllLocation', async () => {
  const response = await axios.get('/qadmin/locations')
  return response.data
})

export const appTranslate = createSlice({
  name: 'appDesks',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: getDeskt(),
    selectedAdmin: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllDesks.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getDesk.fulfilled, (state, action) => {
        state.selectedDesk = action.payload
      })
      .addCase(getAllLocation.fulfilled, (state, action) => {
        state.locations = action.payload
      })
  }
})

export default appTranslate.reducer

import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  staffs: [
    {
      id: 1,
      phone: '+33 456 66 44 66',
      fullName: 'Staff 1',
      staffManager: 'StaffManager 1',
      username: 'gslixby0',
      department: 'Department 1',
      employees: 'Emplyee 1',
      email: 'gslixby0@abc.net.au',
      services: [
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/9.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/9.png').default
      ],
      members: '1 Members',
      status: 'inactive',
      avatar: '',
      avatarColor: 'light-primary'
    },
    {
      id: 2,
      phone: '+33 456 66 44 67',
      fullName: 'Staff 2',
      staffManager: 'StaffManager 2',
      username: 'hredmore1',
      department: 'Department 2',
      employees: 'Emplyee 2',
      email: 'hredmore1@imgur.com',
      services: [
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/9.png').default
      ],
      members: '2 Members',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      id: 3,
      phone: '+33 456 66 44 68',
      fullName: 'Staff 3',
      staffManager: 'StaffManager 3',
      username: 'msicely2',
      department: 'Department 3',
      employees: 'Emplyee 3',
      email: 'msicely2@who.int',
      services: [
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/9.png').default
      ],
      members: '3 Members',
      status: 'active',
      avatar: require('@src/assets/images/avatars/1.png').default
    },
    {
      id: 4,
      phone: '+33 456 66 44 69',
      fullName: 'Staff 4',
      staffManager: 'StaffManager 4',
      username: 'crisby3',
      department: 'Department 4',
      employees: 'Emplyee 4',
      email: 'crisby3@wordpress.com',
      services: [
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/9.png').default
      ],
      members: '4 Members',
      status: 'inactive',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      id: 5,
      phone: '+33 456 66 44 70',
      fullName: 'Staff 5',
      staffManager: 'StaffManager 5',
      username: 'mhurran4',
      department: 'Department 5',
      employees: 'Emplyee 5',
      email: 'mhurran4@yahoo.co.jp',
      services: [
        require('@src/assets/images/avatars/9.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/1.png').default
      ],
      members: '5 Members',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      id: 6,
      phone: '+33 456 66 44 71',
      fullName: 'Staff 6',
      staffManager: 'StaffManager 6',
      username: 'shalstead5',
      department: 'Department 6',
      employees: 'Emplyee 6',
      email: 'shalstead5@shinystat.com',
      services: [
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/9.png').default
      ],
      members: '6 Members',
      status: 'active',
      avatar: '',
      avatarColor: 'light-success'
    },
    {
      id: 7,
      phone: '+33 456 66 44 72',
      fullName: 'Staff 7',
      staffManager: 'StaffManager 7',
      username: 'bgallemore6',
      department: 'Department 7',
      employees: 'Emplyee 7',
      email: 'bgallemore6@boston.com',
      services: [
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/9.png').default
      ],
      members: '7 Members',
      status: 'pending',
      avatar: '',
      avatarColor: 'light-danger'
    },
    {
      id: 8,
      phone: '+33 456 66 44 73',
      fullName: 'Staff 8',
      staffManager: 'StaffManager 8',
      username: 'kliger7',
      department: 'Department 8',
      employees: 'Emplyee 8',
      email: 'kliger7@vinaora.com',
      services: [
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/9.png').default
      ],
      members: '8 Members',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      id: 9,
      phone: '+33 456 66 44 74',
      fullName: 'Staff 9',
      staffManager: 'StaffManager 9',
      username: 'fscotfurth8',
      department: 'Department 9',
      employees: 'Emplyee 9',
      email: 'fscotfurth8@dailymotion.com',
      services: [
        require('@src/assets/images/avatars/2.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/2.png').default,
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/9.png').default
      ],
      members: '9 Members',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/2.png').default
    },
    {
      id: 10,
      phone: '+33 456 66 44 75',
      fullName: 'Staff 10',
      staffManager: 'StaffManager 10',
      username: 'jbellany9',
      department: 'Department 10',
      employees: 'Emplyee 10',
      email: 'jbellany9@kickstarter.com',
      services: [require('@src/assets/images/avatars/10.png').default],
      members: '10 Members',
      status: 'inactive',
      avatar: require('@src/assets/images/avatars/9.png').default
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/staffs/list/all-data').reply(200, data.staffs)

// POST: Add new staff
mock.onPost('/apps/staffs/add-staff').reply((config) => {
  // Get event from post data
  const staff = JSON.parse(config.data)
  const highestValue = data.staffs.reduce((a, b) => (a.id > b.id ? a : b)).id

  staff.id = highestValue + 1

  data.staffs.push(staff)

  return [201, { staff }]
})

// GET Updated DATA
mock.onGet('/api/staffs/list/data').reply((config) => {
  const {
    q = '',
    page = 1,
    perPage = 10,
    sort = 'asc',
    status = null,
    services = null,
    sortColumn = 'fullName'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.staffs.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    (staff) =>
      (staff.email.toLowerCase().includes(queryLowered) ||
        staff.department.toLowerCase().includes(queryLowered) ||
        staff.fullName.toLowerCase().includes(queryLowered) ||
        staff.phone.toLowerCase().includes(queryLowered)) &&
        staff.services === (services || staff.services) &&
        staff.status === (status || staff.status)
  )
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      staffs: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET STAFF
mock.onGet('/api/staffs/staff').reply((config) => {
  const { id } = config
  const staff = data.staffs.find((i) => i.id === id)
  return [200, { staff }]
})

// DELETE: Deletes STAFF
mock.onDelete('/apps/staffs/delete').reply((config) => {
  // Get staff id from URL
  let staffId = config.id

  // Convert Id to number
  staffId = Number(staffId)

  const staffIndex = data.staffs.findIndex((t) => t.id === staffId)
  data.staffs.splice(staffIndex, 1)

  return [200]
})

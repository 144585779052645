// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getBrand = createAsyncThunk('appBrandModel/getBrand', async () => {
  const response = await axios.get('/brands/')
  return response.data
})

export const getModel = createAsyncThunk('appBrandModel/getModel', async (id) => {
  const response = await axios.post('/models/', {brand: id})
  return response.data
})

export const getModels = createAsyncThunk('appBrandModel/getModels', async () => {
  const response = await axios.get('/all-models/')
  return response.data
})

export const brandModelSlice = createSlice({
  name: 'appBrandModel',
  initialState: {
    brand: [],
    model: [],
    models: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getBrand.fulfilled, (state, action) => {
        state.brand = action.payload
      })
      .addCase(getModel.fulfilled, (state, action) => {
        state.model = action.payload
      })
      .addCase(getModels.fulfilled, (state, action) => {
        state.models = action.payload
      })
  }
})

export default brandModelSlice.reducer

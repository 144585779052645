// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { paginateArray } from '@src/@fake-db/utils'

export const getAllServices = createAsyncThunk('appService/getAllServices', async () => {
  const response = await axios.get('/qadmin/services')
  return response.data
})

export const getData = createAsyncThunk('appService/getData', async (params) => {
  const { q = '', page = 1, perPage = 10, sort = 'asc', sortColumn = 'name', allData = [] } = params

  // /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const filteredData = allData.filter(
    service => service.name.toLowerCase().includes(queryLowered)
  )

  const dataAsc = filteredData.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const sendData = dataToFilter
  return {
    params,
    data: paginateArray(sendData, perPage, page),
    totalPages: sendData.length
  }
})

export const getService = createAsyncThunk('appService/getService', async (id) => {
  const response = await axios.get(`/qadmin/service/${id}`)
  return response.data
})

export const addService = createAsyncThunk('appService/addService', async (service, { dispatch, getState }) => {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  }
  await axios.post('/service-create/', service, config)
  await dispatch(getAllServices())
  await dispatch(getData(getState()))
  return service
})

export const updateService = createAsyncThunk('appService/updateService', async ({id, service}, { dispatch, getState }) => {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  }
  await axios.put(`/service/update/${id}`, service, config)
  await dispatch(getAllServices())
  await dispatch(getService(id))
  await dispatch(getData(getState()))
  return service
})

export const deleteService = createAsyncThunk('appService/deleteService', async (id, { dispatch, getState }) => {
  await axios.delete(`/service/${id}`)
  await dispatch(getAllServices())
  await dispatch(getData(getState()))
  return id
})

export const appServiceSlice = createSlice({
  name: 'appService',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    subServiceData: [],
    // qualifications: [],
    // locations: [],
    selectedService: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllServices.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getService.fulfilled, (state, action) => {
        state.selectedService = action.payload
        // state.subServiceData = action.payload.subServices
      })
      // .addCase(getAllQualifications.fulfilled, (state, action) => {
      //   state.qualifications = action.payload
      // })
      // .addCase(getAllLocations.fulfilled, (state, action) => {
      //   state.locations = action.payload
      // })
      
  }
})

export default appServiceSlice.reducer

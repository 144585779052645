// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllRoles = createAsyncThunk('appRole/getAllRoles', async () => {
  const response = await axios.get('/qadmin/roles')
  return response.data
})

export const getData = createAsyncThunk('appRole/getData', async (params) => {
  const response = await axios.get('/api/roles/list/data', params)
  return {
    params,
    data: response.data.roles
  }
})

export const getRole = createAsyncThunk('appRole/getRole', async (id) => {
  const response = await axios.get('/api/roles/role', { id })
  return response.data.role
})

export const deleteRole = createAsyncThunk('appRole/deleteRole', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/roles/delete', { id })
  await dispatch(getData(getState().roles.params))
  await dispatch(getAllRoles())
  return id
})

export const appRoleSlice = createSlice({
  name: 'appRole',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedRole: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getRole.fulfilled, (state, action) => {
        state.selectedRole = action.payload
      })
  }
})

export default appRoleSlice.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { paginateArray } from '@src/@fake-db/utils'

export const getAllTVs = createAsyncThunk('appTVs/getAllTVs', async () => {
  const response = await axios.get('/tvs')
  return response.data
})

export const getAllLocation = createAsyncThunk('appLocations/getAllLocation', async () => {
  const response = await axios.get('/qadmin/locations')
  return response.data
})

export const getData = createAsyncThunk('appTVs/getData', async params => {
  const { q = '', page = 1, perPage = 10, sort = 'asc', sortColumn = 'serial_number', allData = [] } = params

  // /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const filteredData = allData.filter(
    tv => tv.serial_number.toLowerCase().includes(queryLowered) || tv.brand.toLowerCase().includes(queryLowered) || tv.model.toLowerCase().includes(queryLowered)
  )
    console.log(allData)
  const dataAsc = filteredData.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const sendData = dataToFilter
  return {
    params,
    data: paginateArray(sendData, perPage, page),
    totalPages: sendData.length
  }
})

export const getTV = createAsyncThunk('appTVs/getTV', async id => {
  const response = await axios.get(`/tv/${id}`)
  return response.data
})

export const addTV = createAsyncThunk('appTVs/addTV', async (tv, { dispatch, getState }) => {
  const response = await axios.post('/tv-create/', tv)
  await dispatch(getAllTVs())
  await dispatch(getData(getState()))
  return response
})

export const deleteTV = createAsyncThunk('appTVs/deleteTV', async (id, { dispatch, getState }) => {
  await axios.delete(`/tv-delete/${id}`)
  await dispatch(getAllTVs())
  await dispatch(getData(getState()))
  return id
})  

export const updateTV = createAsyncThunk('appTVs/updateTV', async (tv, { dispatch, getState }) => {
  await axios.put(`/tv-update/${tv.id}`, tv)
  await dispatch(getAllTVs())
  await dispatch(getTV(tv.id))
  await dispatch(getData(getState()))
  return tv
})

export const appTVsSlice = createSlice({
  name: 'appTVs',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedTV: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllTVs.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getTV.fulfilled, (state, action) => {
        state.selectedTV = action.payload
      })
      .addCase(getAllLocation.fulfilled, (state, action) => {
        state.locations = action.payload
      })
  }
})

export default appTVsSlice.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { paginateArray } from '@src/@fake-db/utils'

export const getAllDepartment = createAsyncThunk('appDepartments/getAllDepartment', async () => {
  const response = await axios.get('/qadmin/departments')
  return response.data
})

export const getData = createAsyncThunk('appDepartment/getData', async (params) => {
  const { q = '', page = 1, perPage = 10, sort = 'asc', sortColumn = 'name', allData = [] } = params

  // /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const filteredData = allData.filter(
    department => department.name.toLowerCase().includes(queryLowered)
  )

  const dataAsc = filteredData.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const sendData = dataToFilter
  return {
    params,
    data: paginateArray(sendData, perPage, page),
    totalPages: sendData.length
  }
})

export const getDepartment = createAsyncThunk('appDepartment/getDepartment', async (id) => {
  const response = await axios.get(`/qadmin/departments/${id}`)
  return response.data
})

export const addDepartment = createAsyncThunk('appDepartment/addDepartment', async (department, { dispatch, getState }) => {
  await axios.post('/qadmin/department', department)
  .then(res=>console.log(res))
  .catch(error=> console.log(error))
  await dispatch(getData(getState().departments.params))
  await dispatch(getAllDepartment())
  return department
})

export const deleteDepartment = createAsyncThunk('appDepartment/deleteDepartment', async (id, { dispatch, getState }) => {
  await axios.delete(`/qadmin/departments/${id}`)
  await dispatch(getData(getState().departments.params))
  await dispatch(getAllDepartment())
  return id
})

export const updateDepartment = createAsyncThunk('appDepartment/updateDepartment', async ({id, department}, { dispatch, getState }) => {
  await axios.put(`/qadmin/departments/${id}`, department)
  await dispatch(getData(getState().departments.params))
  await dispatch(getDepartment(id))
  await dispatch(getAllDepartment())
  return department
})

export const appDepartmentsSlice = createSlice({
  name: 'appDepartments',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedAdmin: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllDepartment.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getDepartment.fulfilled, (state, action) => {
        state.selectedDepartment = action.payload
      })
  }
})

export default appDepartmentsSlice.reducer

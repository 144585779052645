// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import brandModel from './brand-model'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import staffs from '@src/views/apps/userStaff/store'
import employees from '@src/views/apps/employee/store'
import admins from '@src/views/apps/admin/store'
import roles from '@src/views/apps/role/store'
import departments from '@src/views/apps/department/store'
import locations from '@src/views/apps/location/store'
import services from '@src/views/apps/service/store'
import counters from '@src/views/apps/counter/store'
import kiosks from '@src/views/apps/kiosk/store'
import tvs from '@src/views/apps/tv/store'
import tvGroups from '@src/views/apps/tvGroup/store'
import desks from '@src/views/apps/desk/store'
import email from '@src/views/apps/email/store'
import kanban from '@src/views/apps/kanban/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import videos from '@src/views/apps/video/store'
import playList from '@src/views/apps/playList/store'
import streams from '@src/views/apps/streams/store'
import translate from '@src/views/apps/store'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  kanban,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  staffs,
  employees,
  admins,
  roles,
  departments,
  locations,
  services,
  counters,
  kiosks,
  tvs,
  tvGroups,
  brandModel,
  desks,
  videos,
  playList,
  streams,
  translate
}

export default rootReducer

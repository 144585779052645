// You can customize the template with the help of this file
import {
  Airplay,
  Box,
  Camera,
  Copy,
  DollarSign,
  Home,
  MapPin,
  Users,
  Edit
} from 'react-feather'

// const displayLogo = () => {
//   return(
//     {
//       appName: 'Logo',
//       appLogoImage: require('@src/assets/images/logo/logo.svg').default
//     }
//   )
// }
//Template config options

const themeConfig = {
  app: {
    appName: 'Logo',
    appLogoImage: require('@src/assets/images/logo/logo.svg').default
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    type: 'vertical', // vertical, horizontal
    contentWidth: 'boxed', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    menuData: [
      {
        id:"Dashboards",
        title: 'Dashboards',
        icon: <Home />
      },
      {
        id: 'users',
        title: 'Users',
        icon: <Users />,
        children: [
          {
            id: 'staff',
            title: 'Staff',
            navLink: '/apps/userstaff/list'
          },
          {
            id: 'employee',
            title: 'Employees',
            navLink: '/apps/employee/list'
          },
          {
            id: 'admin',
            title: 'Admins',
            navLink: '/apps/admin/list'
          },
          {
            id: 'role',
            title: 'Roles',
            navLink: '/apps/role/list'
          }
        ]
      },
      {
        id: 'departement',
        title: 'Departments',
        icon: <Copy />,
        navLink: '/apps/department/list'
      },
      {
        id: 'location',
        title: 'Locations',
        icon: <MapPin size={20} />,
        children: [
          {
            id: 'location',
            title: 'Locations',
            navLink: '/apps/location/list'
          },
          {
            id: 'desk',
            title: 'Desks',
            navLink: '/apps/desk/list'
          }
        ]
      },
      {
        id: 'services',
        title: 'Services',
        icon: <Box />, 
        children: [
          {
            id: 'service',
            title: 'Services',
            navLink: '/apps/service/list'
          },
          {
            id: 'counter',
            title: 'Counters',
            navLink: '/apps/counter/list'
          }
        ]
      },
      {
        id: 'device',
        title: 'Devices',
        icon: <Airplay />,
        children: [
          {
            id: 'kiosk',
            title: 'Kiosks',
            navLink: '/apps/kiosk/list'
          },
          {
            id: 'tv',
            title: 'TV',
            navLink: '/apps/tv/list'
          },
          {
            id: 'tvGroup',
            title: 'TV Groups',
            navLink: '/apps/tvGroup/list'
          }
        ]
      },
      {
        id: 'media',
        title: 'Medias',
        icon: <Camera />,
        children: [
          {
            id: 'videos',
            title: 'Videos',
            navLink: '/apps/video/list'
          },
          {
            id: 'playlist',
            title: 'Playlist',
            navLink: '/apps/playList/list'
          },
          {
            id: 'streams',
            title: 'Streams',
            navLink: '/apps/streams/list'
          }
        ]
      },
      {
        id: 'appointment',
        title: 'appointment',
        icon: <Edit />,
        navLink: '/apps/calendar'
      },
      {
        id: 'finanse',
        title: 'Finanses',
        icon: <DollarSign />,
        navLink: '/apps/invoice/list'
      }
    ],
    customizer: false,
    scrollTop: true, // Enable scroll to top button
    toastPosition: 'top-right' // top-left, top-center, top-right, bottom-left, bottom-center, bottom-right
  }
}

export default themeConfig

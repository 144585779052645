// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { paginateArray } from '@src/@fake-db/utils'
import { getAllDepartment } from '../../department/store'

export const getAllAdmin = createAsyncThunk('appAdmin/getAllAdmin', async () => {
  const response = await axios.get('/qadmin/admins-list')
  return response.data
})

export const getData = createAsyncThunk('appAdmin/getData', async (params) => {
  const { q = '', page = 1, perPage = 10, sort = 'asc', sortColumn = 'email', allData = [] } = params

  // /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const filteredData = allData.filter(
    admin => admin.email.toLowerCase().includes(queryLowered) ||
      admin.username.toLowerCase().includes(queryLowered) || admin.first_name.toLowerCase().includes(queryLowered) 
  )

  const dataAsc = filteredData.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const sendData = dataToFilter
  return {
    params,
    data: paginateArray(sendData, perPage, page),
    totalPages: sendData.length
  }
})

export const getAdmin = createAsyncThunk('appAdmin/getAdmin', async (id) => {
  const response = await axios.get(`/admin/${id}`)
  return response.data
})

export const addAdmin = createAsyncThunk('appAdmin/addAdmin', async (admin, { dispatch, getState }) => {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  }
  await axios.post('/qadmin/add-admin', admin, config)
  await dispatch(getData(getState().admins.params))
  await dispatch(getAllAdmin())
  return admin
})

export const deleteAdmin = createAsyncThunk('appAdmin/deleteAdmin', async (id, { dispatch, getState }) => {
  await axios.delete(`/admin/${id}`)
  await dispatch(getAllAdmin())
  await dispatch(getAdmin(id))
  await dispatch(getData(getState()))
  return id
})

// export const deleteMultiple = createAsyncThunk(
//   'appAdmin/deleteMultiple',
//   async ({ ids, model_name }, { dispatch, getState }) => {
//     await axios.delete('/qadmin/suppression-multiple/', { data: { ids, model_name } })
//     await dispatch(getAllAdmin())
//     await dispatch(getData(getState()))
//     await dispatch(getAllDepartment())
//     return ids
//   }
// )

export const updateAdmin = createAsyncThunk(
  'appAdmin/updateAdmin',
  async ({id, admin}, { dispatch, getState }) => {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    await axios.put(`/admin/${id}`, admin, config)
    await dispatch(getAllAdmin())
    await dispatch(getAdmin(id))
    await dispatch(getData(getState()))
    return admin
  }
)

export const appAdminSlice = createSlice({
  name: 'appAdmin',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedAdmin: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllAdmin.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getAdmin.fulfilled, (state, action) => {
        state.selectedAdmin = action.payload
      })
  }
})

export default appAdminSlice.reducer

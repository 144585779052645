// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { paginateArray } from '@src/@fake-db/utils'

export const getAllStreams = createAsyncThunk('appStreams/getAllStreams', async () => {
  const response = await axios.get('/qadmin/stream-list')
  return response.data
})

export const getData = createAsyncThunk('appStreams/getData', async (params) => {
  const { q = '', page = 1, perPage = 10, sort = 'asc', sortColumn = 'email', allData = [] } = params

  // /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const filteredData = allData.filter(
    admin => admin.name.toLowerCase().includes(queryLowered)
  )

  const dataAsc = filteredData.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const sendData = dataToFilter
  return {
    params,
    data: paginateArray(sendData, perPage, page),
    totalPages: sendData.length
  }
})

export const getStream = createAsyncThunk('appStreams/getStream', async (id) => {
  const response = await axios.get(`/qadmin/stream/${id}`)
  return response.data
})

export const addStream = createAsyncThunk('appStreams/addStream', async (streams, { dispatch, getState }) => {
  
  await axios.post('/qadmin/stream-add', streams)
  await dispatch(getData(getState().streams.params))
  await dispatch(getAllStreams())
  return streams
})

export const deleteStream = createAsyncThunk('appStreams/deleteStream', async (id, { dispatch, getState }) => {
  await axios.delete(`/qadmin/stream-delete/${id}`)
  await dispatch(getAllStreams())
  await dispatch(getStream(id))
  await dispatch(getData(getState()))
  return id
})

export const updateStream = createAsyncThunk(
  'appStreams/updateStream',
  async ({id, stream}, { dispatch, getState }) => {
    await axios.put(`/qadmin/stream-update/${id}`, stream)
    await dispatch(getAllStreams())
    await dispatch(getStream(id))
    await dispatch(getData(getState()))
    return stream
  }
)

export const appStreamSlice = createSlice({
  name: 'appStreams',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedStreams: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllStreams.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getStream.fulfilled, (state, action) => {
        state.selectedStreams = action.payload
      })
  }
})

export default appStreamSlice.reducer

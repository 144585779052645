// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { paginateArray } from '@src/@fake-db/utils'

export const getAllCounter = createAsyncThunk('appCounters/getAllCounter', async () => {
  const response = await axios.get('/counter-list')
  // const response = await axios.get('/api/counters/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('appCounters/getData', async params => {
  const { q = '', page = 1, perPage = 10, sort = 'asc', sortColumn = 'name', allData = [] } = params

  // /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const filteredData = allData.filter(
    counter => counter.name.toLowerCase().includes(queryLowered) || counter.publication_date.toLowerCase().includes(queryLowered)
  )

  const dataAsc = filteredData.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const sendData = dataToFilter
  return {
    params,
    data: paginateArray(sendData, perPage, page),
    totalPages: sendData.length
  }
})

export const getCounter = createAsyncThunk('appCounters/getCounter', async (id) => {
  const response = await axios.get(`/counters/${id}`)
  return response.data
})

export const addCounter = createAsyncThunk('appCounters/addCounter', async (counter, { dispatch, getState }) => {
  await axios.post('/counter/', counter)
  await dispatch(getAllCounter())
  await dispatch(getData(getState()))
  return counter
})

export const deleteCounter = createAsyncThunk('appCounters/deleteCounter', async (id, { dispatch, getState }) => {
  await axios.delete(`/counters/${id}`)
  await dispatch(getAllCounter())
  await dispatch(getData(getState()))
  return id
})  

export const updateCounter = createAsyncThunk('appCounters/updateCounter', async (counter, { dispatch, getState }) => {
  await axios.put(`/counters/${counter.id}`, counter)
  await dispatch(getAllCounter())
  await dispatch(getCounter(counter.id))
  await dispatch(getData(getState()))
  return counter
})

export const appCountersSlice = createSlice({
  name: 'appCounters',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedCounter: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllCounter.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getCounter.fulfilled, (state, action) => {
        state.selectedCounter = action.payload
      })
  }
})

export default appCountersSlice.reducer

import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  admins: [
    {
      id: 1,
      fullName: 'FullName 1',
      username: 'gslixby0',
      password: 'Password 1',
      email: 'gslixby0@abc.net.au',
      avatar: '',
      avatarColor: 'light-primary'
    },
    {
      id: 2,
      fullName: 'FullName 2',
      username: 'hredmore1',
      password: 'Password 2',
      email: 'test2@abc.com',
      avatar: '',
      avatarColor: 'light-primary'
    },
    {
      id: 3,
      fullName: 'FullName 3',
      username: 'username 3',
      password: 'Password 3',
      email: 'test3@abc.com',
      avatar: '',
      avatarColor: 'light-primary'
    },
    {
      id: 4,
      fullName: 'FullName 4',
      username: 'username 4',
      password: 'Password 4',
      email: 'test4@abc.com',
      avatar: '',
      avatarColor: 'light-primary'
    },
    {
      id: 5,
      fullName: 'FullName 5',
      username: 'username 5',
      password: 'Password 5',
      email: 'test5@abc.com',
      avatar: '',
      avatarColor: 'light-primary'
    },
    {
      id: 6,
      fullName: 'FullName 6',
      username: 'username 6',
      password: 'Password 6',
      email: 'test6@abc.com',
      avatar: '',
      avatarColor: 'light-primary'
    },
    {
      id: 7,
      fullName: 'FullName 7',
      username: 'username 7',
      password: 'Password 7',
      email: 'test7@abc.com',
      avatar: '',
      avatarColor: 'light-primary'
    },
    {
      id: 8,
      fullName: 'FullName 8',
      username: 'username 8',
      password: 'Password 8',
      email: 'test8@abc.com',
      avatar: '',
      avatarColor: 'light-primary'
    },
    {
      id: 9,
      fullName: 'FullName 9',
      username: 'username 9',
      password: 'Password 9',
      email: 'test9@abc.com',
      avatar: '',
      avatarColor: 'light-primary'
    },
    {
      id: 10,
      fullName: 'FullName 10',
      username: 'username 10',
      password: 'Password 10',
      email: 'test10@abc.com',
      avatar: '',
      avatarColor: 'light-primary'
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/admins/list/all-data').reply(200, data.admins)

// POST: Add new ADMIN
mock.onPost('/apps/admins/add-admin').reply((config) => {
  // Get event from post data
  const admin = JSON.parse(config.data)
  const highestValue = data.admins.reduce((a, b) => (a.id > b.id ? a : b)).id

  admin.id = highestValue + 1

  data.admins.push(admin)

  return [201, { admin }]
})

// GET Updated DATA
mock.onGet('/api/admins/list/data').reply((config) => {
  const {
    q = '',
    page = 1,
    perPage = 10,
    sort = 'asc',
    sortColumn = 'fullName'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.admins.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    (admin) =>
      (admin.email.toLowerCase().includes(queryLowered) ||
        admin.fullName.toLowerCase().includes(queryLowered) ||
        admin.username.toLowerCase().includes(queryLowered))
  )
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      admins: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET ADMIN
mock.onGet('/api/admins/admin').reply((config) => {
  const { id } = config
  const admin = data.admins.find((i) => i.id === id)
  return [200, { admin }]
})

// DELETE: Deletes ADMIN
mock.onDelete('/apps/admins/delete').reply((config) => {
  // Get admin id from URL
  let adminId = config.id

  // Convert Id to number
  adminId = Number(adminId)

  const adminIndex = data.admins.findIndex((t) => t.id === adminId)
  data.admins.splice(adminIndex, 1)

  return [200]
})

import mock from '../mock'

const data = {
  roles: [
    {
      id: 1,
      roleName: 'Staff Manager',
      description: 'This is staff manager',
      role: 'staff'
    },
    {
      id: 2,
      roleName: 'Counter Desk',
      description: 'This is Counter Desk',
      role: 'counter'
    },
    {
      id: 3,
      roleName: 'Finance',
      description: 'This is Finance',
      role: 'finance'
    },
    {
      id: 4,
      roleName: 'Com',
      description: 'This is Com',
      role: 'com'
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/roles/list/all-data').reply(200, data.roles)

// GET role
mock.onGet('/api/roles/role').reply((config) => {
  const { id } = config
  const role = data.roles.find((i) => i.id === id)
  return [200, { role }]
})

// DELETE: Deletes role
mock.onDelete('/apps/roles/delete').reply((config) => {
  // Get role id from URL
  let roleId = config.id

  // Convert Id to number
  roleId = Number(roleId)

  const roleIndex = data.roles.findIndex((t) => t.id === roleId)
  data.roles.splice(roleIndex, 1)

  return [200]
})

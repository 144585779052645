import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  kiosks: [
    {
      id: 1,
      kioskName: 'Kiosk Name 1',
      brand: 'Brand 1',
      model: 'Model 1',
      ip_address: '192.0.21',
      mac_address: '00:26:57:00:1f:02',
      location: 'Location 1',
      status: 'active',
      description: 'This is description 1 for kiosk 1',
      services: [
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/9.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/9.png').default
      ]
    },
    {
      id: 2,
      kioskName: 'Kiosk Name 2',
      brand: 'Brand 2',
      model: 'Model 2',
      ip_address: '192.0.21',
      mac_address: '00:26:57:00:1f:02',
      location: 'Location 2',
      status: 'unactive',
      description: 'This is description 2 for kiosk 2',
      services: [
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/9.png').default
      ]
    },
    {
      id: 3,
      kioskName: 'Kiosk Name 3',
      brand: 'Brand 3',
      model: 'Model 3',
      ip_address: '192.0.21',
      mac_address: '00:26:57:00:1f:02',
      location: 'Location 3',
      status: 'active',
      description: 'This is description 3 for kiosk 3',
      services: [
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/9.png').default
      ]
    },
    {
      id: 4,
      kioskName: 'Kiosk Name 4',
      brand: 'Brand 4',
      model: 'Model 4',
      ip_address: '192.0.21',
      mac_address: '00:26:57:00:1f:02',
      location: 'Location 4',
      status: 'unactive',
      description: 'This is description 4 for kiosk 4',
      services: [
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/9.png').default
      ]
    },
    {
      id: 5,
      kioskName: 'Kiosk Name 5',
      brand: 'Brand 5',
      model: 'Model 5',
      ip_address: '192.0.21',
      mac_address: '00:26:57:00:1f:02',
      location: 'Location 5',
      status: 'unactive',
      description: 'This is description 5 for kiosk 5',
      services: [
        require('@src/assets/images/avatars/9.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/1.png').default
      ]
    },
    {
      id: 6,
      kioskName: 'Kiosk Name 6',
      brand: 'Brand 6',
      model: 'Model 6',
      ip_address: '192.0.21',
      mac_address: '00:26:57:00:1f:02',
      location: 'Location 6',
      status: 'unactive',
      description: 'This is description 6 for kiosk 6',
      services: [
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/9.png').default
      ]
    },
    {
      id: 7,
      kioskName: 'Kiosk Name 7',
      brand: 'Brand 7',
      model: 'Model 7',
      ip_address: '192.0.21',
      mac_address: '00:26:57:00:1f:02',
      location: 'Location 7',
      status: 'active',
      description: 'This is description 7 for kiosk 7',
      services: [
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/9.png').default
      ]
    },
    {
      id: 8,
      kioskName: 'Kiosk Name 8',
      brand: 'Brand 8',
      model: 'Model 8',
      ip_address: '192.0.21',
      mac_address: '00:26:57:00:1f:02',
      location: 'Location 8',
      status: 'active',
      description: 'This is description 8 for kiosk 8',
      services: [
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/9.png').default
      ]
    },
    {
      id: 9,
      kioskName: 'Kiosk Name 9',
      brand: 'Brand 9',
      model: 'Model 9',
      ip_address: '192.0.21',
      mac_address: '00:26:57:00:1f:02',
      location: 'Location 9',
      status: 'active',
      description: 'This is description 9 for kiosk 9',
      services: [
        require('@src/assets/images/avatars/2.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/2.png').default,
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/9.png').default
      ]
    },
    {
      id: 10,
      kioskName: 'Kiosk Name 10',
      brand: 'Brand 10',
      model: 'Model 10',
      ip_address: '192.0.21',
      mac_address: '00:26:57:00:1f:02',
      location: 'Location 10',
      status: 'unactive',
      description: 'This is description 10 for kiosk 10',
      services: [require('@src/assets/images/avatars/10.png').default]
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/kiosks/list/all-data').reply(200, data.kiosks)

// POST: Add new kiosk
mock.onPost('/apps/kiosks/add-kiosk').reply((config) => {
  // Get event from post data
  const kiosk = JSON.parse(config.data)
  const highestValue = data.kiosks.reduce((a, b) => (a.id > b.id ? a : b)).id

  kiosk.id = highestValue + 1

  data.kiosks.push(kiosk)

  return [201, { kiosk }]
})

// GET Updated DATA
mock.onGet('/api/kiosks/list/data').reply((config) => {
  const {
    q = '',
    page = 1,
    perPage = 10,
    sort = 'asc',
    sortColumn = 'fullName'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.kiosks.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()
  const filteredData = dataToFilter.filter(
    (kiosk) =>
      (
        kiosk.kioskName.toLowerCase().includes(queryLowered) ||
        kiosk.brand.toLowerCase().includes(queryLowered) ||
        kiosk.model.toLowerCase().includes(queryLowered) ||
        kiosk.location.toLowerCase().includes(queryLowered) 
        ))
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      kiosks: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET kiosk
mock.onGet('/api/kiosks/kiosk').reply((config) => {
  const { id } = config
  const kiosk = data.kiosks.find((i) => i.id === id)
  return [200, { kiosk }]
})

// DELETE: Deletes kiosk
mock.onDelete('/apps/kiosks/delete').reply((config) => {
  // Get kiosk id from URL
  let kioskId = config.id

  // Convert Id to number
  kioskId = Number(kioskId)

  const kioskIndex = data.kiosks.findIndex((t) => t.id === kioskId)
  data.kiosks.splice(kioskIndex, 1)

  return [200]
})

import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  departments: [
    {
      id: 1,
      department:'department 1',
      description:'Lorem Ipsum is simply dummy text of the printing',
      date:'14/10/2022',
      status: 'Unactive'
    },
    {
      id: 2,
      department:'department 2',
      description:'Lorem Ipsum is simply dummy text of the printing',
      date:'14/10/2022',
      status: 'Unactive'
    },
    {
      id: 3,
      department:'department 1',
      description:'Lorem Ipsum is simply dummy text of the printing',
      date:'14/10/2022',
      status: 'Unactive'
    },
    {
      id: 4,
      department:'department 1',
      description:'Lorem Ipsum is simply dummy text of the printing',
      date:'14/10/2022',
      status: 'Unactive'
    },
    {
      id: 5,
      department:'department 1',
      description:'Lorem Ipsum is simply dummy text of the printing',
      date:'14/10/2022',
      status: 'Unactive'
    },
    {
      id: 6,
      department:'department 1',
      description:'Lorem Ipsum is simply dummy text of the printing',
      date:'14/10/2022',
      status: 'Unactive'
    },
    {
      id: 7,
      department:'department 1',
      description:'Lorem Ipsum is simply dummy text of the printing',
      date:'14/10/2022',
      status: 'Unactive'
    },
    {
      id: 8,
      department:'department 1',
      description:'Lorem Ipsum is simply dummy text of the printing',
      date:'14/10/2022',
      status: 'Unactive'
    },
    {
      id: 9,
      department:'department 1',
      description:'Lorem Ipsum is simply dummy text of the printing',
      date:'14/10/2022',
      status: 'Unactive'
    },
    {
      id: 10,
      department:'department 1',
      description:'Lorem Ipsum is simply dummy text of the printing',
      date:'14/10/2022',
      status: 'Unactive'
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/departments/list/all-data').reply(200, data.departments)

// POST: Add new staff
mock.onPost('/apps/departments/add-department').reply((config) => {
  // Get event from post data
  const department = JSON.parse(config.data)
  const highestValue = data.departments.reduce((a, b) => (a.id > b.id ? a : b)).id

  department.id = highestValue + 1

  data.departments.push(department)

  return [201, { department }]
})

// GET Updated DATA
mock.onGet('/api/departments/list/data').reply((config) => {
  const {
    q = '',
    page = 1,
    perPage = 10,
    sort = 'asc',
    status = null,
    services = null,
    sortColumn = 'department'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.departments.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    (department) =>
      (department.department.toLowerCase().includes(queryLowered) ||
        department.description.toLowerCase().includes(queryLowered) ||
        department.date.toLowerCase().includes(queryLowered)) &&
        department.status === (status || department.status)
  )
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      departments: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET department
mock.onGet('/api/departments/department').reply((config) => {
  const { id } = config
  const department = data.departments.find((i) => i.id === id)
  return [200, { department }]
})

// DELETE: Deletes department
mock.onDelete('/apps/departments/delete').reply((config) => {
  // Get department id from URL
  let departmentId = config.id

  // Convert Id to number
  departmentId = Number(departmentId)

  const departmentIndex = data.departments.findIndex((t) => t.id === departmentId)
  data.departments.splice(departmentIndex, 1)

  return [200]
})

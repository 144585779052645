import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  counters: [
    {
      id: 1,
      counterName: 'Counter Name 1',
      dateCreate: '22/10/2022',
      customers: 15,
      staff: 'Staff Name 1',
      department: 'Department 1',
      owner: 'Manager',
      location: 'Location 1',
      description: 'This is description 1 for counter 1',
      services: [
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/9.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/9.png').default
      ]
    },
    {
      id: 2,
      counterName: 'Counter Name 2',
      dateCreate: '23/10/2022',
      customers: 10,
      staff: 'Staff Name 2',
      department: 'Department 2',
      owner: 'Author',
      location: 'Location 2',
      description: 'This is description 2 for counter 2',
      services: [
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/9.png').default
      ]
    },
    {
      id: 3,
      counterName: 'Counter Name 3',
      dateCreate: '23/10/2022',
      customers: 3,
      staff: 'Staff Name 3',
      department: 'Department 3',
      location: 'Location 3',
      owner: 'User',
      description: 'This is description 3 for counter 3',
      services: [
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/9.png').default
      ]
    },
    {
      id: 4,
      counterName: 'Counter Name 4',
      dateCreate: '24/10/2022',
      customers: 4,
      staff: 'Staff Name 4',
      department: 'Department 4',
      location: 'Location 4',
      owner: 'Manager',
      description: 'This is description 4 for counter 4',
      services: [
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/9.png').default
      ]
    },
    {
      id: 5,
      counterName: 'Counter Name 5',
      dateCreate: '25/10/2022',
      customers: 5,
      staff: 'Staff Name 5',
      department: 'Department 5',
      location: 'Location 5',
      owner: 'Author',
      description: 'This is description 5 for counter 5',
      services: [
        require('@src/assets/images/avatars/9.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/1.png').default
      ]
    },
    {
      id: 6,
      counterName: 'Counter Name 6',
      dateCreate: '26/10/2022',
      customers: 6,
      staff: 'Staff Name 6',
      department: 'Department 6',
      location: 'Location 6',
      owner: 'Manager',
      description: 'This is description 6 for counter 6',
      services: [
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/9.png').default
      ]
    },
    {
      id: 7,
      counterName: 'Counter Name 7',
      dateCreate: '27/10/2022',
      customers: 7,
      staff: 'Staff Name 7',
      department: 'Department 7',
      location: 'Location 7',
      owner: 'User',
      description: 'This is description 7 for counter 7',
      services: [
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/9.png').default
      ]
    },
    {
      id: 8,
      counterName: 'Counter Name 8',
      dateCreate: '28/10/2022',
      customers: 8,
      staff: 'Staff Name 8',
      department: 'Department 8',
      location: 'Location 8',
      owner: 'User',
      description: 'This is description 8 for counter 8',
      services: [
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/9.png').default
      ]
    },
    {
      id: 9,
      counterName: 'Counter Name 9',
      dateCreate: '29/10/2022',
      customers: 9,
      staff: 'Staff Name 9',
      department: 'Department 9',
      location: 'Location 9',
      owner: 'User',
      description: 'This is description 9 for counter 9',
      services: [
        require('@src/assets/images/avatars/2.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/2.png').default,
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/9.png').default
      ]
    },
    {
      id: 10,
      counterName: 'Counter Name 10',
      dateCreate: '30/10/2022',
      customers: 10,
      staff: 'Staff Name 10',
      department: 'Department 10',
      location: 'Location 10',
      owner: 'Admin',
      description: 'This is description 10 for counter 10',
      services: [require('@src/assets/images/avatars/10.png').default]
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/counters/list/all-data').reply(200, data.counters)

// POST: Add new counter
mock.onPost('/apps/counters/add-counter').reply((config) => {
  // Get event from post data
  const counter = JSON.parse(config.data)
  const highestValue = data.counters.reduce((a, b) => (a.id > b.id ? a : b)).id

  counter.id = highestValue + 1

  data.counters.push(counter)

  return [201, { counter }]
})

// GET Updated DATA
mock.onGet('/api/counters/list/data').reply((config) => {
  const {
    q = '',
    page = 1,
    perPage = 10,
    sort = 'asc',
    sortColumn = 'fullName'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.counters.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()
  const filteredData = dataToFilter.filter(
    (counter) =>
      (
        counter.counterName.toLowerCase().includes(queryLowered) ||
        counter.department.toLowerCase().includes(queryLowered) ||
        counter.location.toLowerCase().includes(queryLowered) 
        ))
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      counters: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET counter
mock.onGet('/api/counters/counter').reply((config) => {
  const { id } = config
  const counter = data.counters.find((i) => i.id === id)
  return [200, { counter }]
})

// DELETE: Deletes counter
mock.onDelete('/apps/counters/delete').reply((config) => {
  // Get counter id from URL
  let counterId = config.id

  // Convert Id to number
  counterId = Number(counterId)

  const counterIndex = data.counters.findIndex((t) => t.id === counterId)
  data.counters.splice(counterIndex, 1)

  return [200]
})

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { paginateArray } from '@src/@fake-db/utils'

export const getAllEmployees = createAsyncThunk('appStaffs/getAllEmployees', async () => {
  const response = await axios.get('/qadmin/employees-list')
  return response.data
})

export const getAllDepartment = createAsyncThunk('appDepartments/getAllDepartment', async () => {
  const response = await axios.get('/qadmin/departments')
  return response.data
})

export const getAllLocations = createAsyncThunk('appLocations/getAllLocation', async () => {
  const response = await axios.get('/qadmin/locations')
  return response.data
})

export const getData = createAsyncThunk('appEmployee/getData', async (params) => {
  const { q = '', page = 1, perPage = 10, sort = 'asc', sortColumn = 'email', allData = [] } = params

  // /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const filteredData = allData.filter(
    (employee) => employee.email.toLowerCase().includes(queryLowered) || employee.username.toLowerCase().includes(queryLowered) 
  )
    
  const dataAsc = filteredData.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const sendData = dataToFilter
  return {
    params,
    data: paginateArray(sendData, perPage, page),
    totalPages: sendData.length
  }
})

export const getEmployee = createAsyncThunk('appEmployee/getEmployee', async (id) => {
  const response = await axios.get(`/qadmin/employee/${id}`)
  return response.data
})

export const addEmployee = createAsyncThunk('appEmployee/addEmployee', async (employee, { dispatch, getState }) => {
  let success = false
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  }
  const response = await axios.post('/qadmin/add-employee/', employee, config)
  .then(() => {
    success = true
    return {}
  })
  .catch(err => err.response.data)
  await dispatch(getData(getState().employees.params))
  await dispatch(getAllEmployees())
  return {
    error: response,
    success
  }
})

export const updateEmployee = createAsyncThunk('appEmployee/updateEmployee', async ({id, employee}, { dispatch, getState }) => {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  }
  const response = await axios.put(`/qadmin/employee/${id}`, employee, config)
  await dispatch(getAllEmployees())
  await dispatch(getEmployee(id))
  await dispatch(getData(getState().employees.params))
  return response.data
})

export const deleteEmployee = createAsyncThunk('appEmployee/deleteEmployee', async (id, { dispatch, getState }) => {
  await axios.delete(`/qadmin/employee/${id}`)
  await dispatch(getAllEmployees())
  await dispatch(getEmployee(id))
  await dispatch(getData(getState()))
  return id
})

export const getAllQualifications = createAsyncThunk('appEmployee/getAllQualifications', async () => {
  const response = await axios.get('/qadmin/qualifications/')
  return response.data
})


export const appEmployeeSlice = createSlice({
  name: 'appEmployee',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    qualifications: [],
    locations: [],
    selectedEmployee: null,
    error: {},
    success: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllEmployees.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getEmployee.fulfilled, (state, action) => {
        state.selectedEmployee = action.payload
      })
      .addCase(getAllQualifications.fulfilled, (state, action) => {
        state.qualifications = action.payload
      })
      .addCase(getAllLocations.fulfilled, (state, action) => {
        state.locations = action.payload
      })
      .addCase(addEmployee.fulfilled, (state, action) => {
        state.error = action.payload.error
        state.success = action.payload.success
      })
      .addCase(getAllDepartment.fulfilled, (state, action) => {
        state.departments = action.payload
      })
  }
})

export default appEmployeeSlice.reducer

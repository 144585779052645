import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  employees: [
    {
      id: 1,
      fullName: 'Username 1',
      location: 'location 1',
      username: 'gslixby0',
      department: 'Department 1',
      role: ['User', 'Admin', 'Staff', 'Author', 'Contributor'],
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      password: '123456',
      qualification: 'Staff',
      avatar: '',
      avatarColor: 'light-primary'
    },
    {
      id: 2,
      fullName: 'Username 2',
      location: 'location 2',
      username: 'hredmore1',
      department: 'Department 2',
      role: ['User', 'Staff', 'Author'],
      email: 'hredmore1@imgur.com',
      password: '123456',
      qualification: 'Com',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      id: 3,
      fullName: 'Username 3',
      location: 'location 3',
      username: 'msicely2',
      department: 'Department 3',
      role: ['User', 'Author', 'Contributor'],
      email: 'msicely2@who.int',
      password: '123456',
      qualification: 'Contributor',
      status: 'active',
      avatar: require('@src/assets/images/avatars/1.png').default
    },
    {
      id: 4,
      fullName: 'Username 4',
      location: 'location 4',
      username: 'crisby3',
      department: 'Department 4',
      role: ['Admin', 'Staff', 'Author'],
      email: 'crisby3@wordpress.com',
      password: '123456',
      qualification: 'Staff',
      status: 'inactive',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      id: 5,
      fullName: 'Username 5',
      location: 'location 5',
      username: 'mhurran4',
      department: 'Department 5',
      role: ['User', 'Contributor'],
      email: 'mhurran4@yahoo.co.jp',
      password: '123456',
      qualification: 'User',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      id: 6,
      fullName: 'Username 6',
      location: 'location 6',
      username: 'shalstead5',
      department: 'Department 6',
      role: ['User', 'Staff', 'Author', 'Contributor'],
      email: 'shalstead5@shinystat.com',
      password: '123456',
      qualification: 'User',
      status: 'active',
      avatar: '',
      avatarColor: 'light-success'
    },
    {
      id: 7,
      fullName: 'Username 7',
      location: 'location 7',
      username: 'bgallemore6',
      department: 'Department 7',
      role: ['Author'],
      email: 'bgallemore6@boston.com',
      password: '123456',
      qualification: 'Staff',
      status: 'pending',
      avatar: '',
      avatarColor: 'light-danger'
    },
    {
      id: 8,
      fullName: 'Username 8',
      location: 'location 8',
      username: 'kliger7',
      department: 'Department 8',
      role: ['User', 'Admin'],
      email: 'kliger7@vinaora.com',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      id: 9,
      fullName: 'Username 9',
      location: 'location 9',
      username: 'fscotfurth8',
      department: 'Department 9',
      role: ['Admin'],
      email: 'fscotfurth8@dailymotion.com',
      password: '123456',
      qualification: 'Com',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/2.png').default
    },
    {
      id: 10,
      fullName: 'Username 10',
      location: 'location 10',
      username: 'jbellany9',
      department: 'Department 10',
      role: ['Admin', 'Staff', 'Author', 'Contributor'],
      email: 'jbellany9@kickstarter.com',
      password: '123456',
      qualification: 'Contributor',
      status: 'inactive',
      avatar: require('@src/assets/images/avatars/9.png').default
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/employees/list/all-data').reply(200, data.employees)

// POST: Add new employee
mock.onPost('/apps/employees/add-employee').reply((config) => {
  // Get event from post data
  const employee = JSON.parse(config.data)
  const highestValue = data.employees.reduce((a, b) => (a.id > b.id ? a : b)).id

  employee.id = highestValue + 1

  data.employees.push(employee)

  return [201, { employee }]
})

// GET Updated DATA
mock.onGet('/api/employees/list/data').reply((config) => {
  const {
    q = '',
    page = 1,
    perPage = 10,
    sort = 'asc',
    status = null,
    role = null,
    sortColumn = 'fullName'
  } = config
  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.employees.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    (employee) =>
      (employee.email.toLowerCase().includes(queryLowered) ||
        employee.fullName.toLowerCase().includes(queryLowered) ||
        employee.department.toLowerCase().includes(queryLowered) ||
        employee.location.toLowerCase().includes(queryLowered)) &&
        employee.role === (role || employee.role) &&
        employee.status === (status || employee.status)
  )
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      employees: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET EMPLOYEE
mock.onGet('/api/employees/employee').reply((config) => {
  const { id } = config
  const employee = data.employees.find((i) => i.id === id)
  return [200, { employee }]
})

// DELETE: Deletes EMPLOYEE
mock.onDelete('/apps/employees/delete').reply((config) => {
  // Get employee id from URL
  let employeeId = config.id

  // Convert Id to number
  employeeId = Number(employeeId)

  const employeeIndex = data.employees.findIndex((t) => t.id === employeeId)
  data.employees.splice(employeeIndex, 1)

  return [200]
})

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { paginateArray } from '@src/@fake-db/utils'

export const getAllKiosks = createAsyncThunk('appKiosks/getAllKiosks', async () => {
  const response = await axios.get('/v1/terminals/')
  return response.data
})

export const getData = createAsyncThunk('appKiosks/getData', async params => {
  const { q = '', page = 1, perPage = 10, sort = 'asc', sortColumn = 'name', allData = [] } = params

  // /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const filteredData = allData.filter(
    kiosk => kiosk.name.toLowerCase().includes(queryLowered) || kiosk.ip.toLowerCase().includes(queryLowered) || kiosk.mac_address.toLowerCase().includes(queryLowered)
  )

  const dataAsc = filteredData.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const sendData = dataToFilter
  return {
    params,
    data: paginateArray(sendData, perPage, page),
    totalPages: sendData.length
  }
})

export const getKiosk = createAsyncThunk('appKiosks/getKiosk', async id => {
  const response = await axios.get(`/v1/terminal/list/${id}`)
  return response.data
})

export const addKiosk = createAsyncThunk('appKiosks/addKiosk', async (kiosk, { dispatch, getState }) => {
  await axios.post('/terminal-create/', kiosk)
  await dispatch(getAllKiosks())
  await dispatch(getData(getState()))
  return kiosk
})

export const deleteKiosk = createAsyncThunk('appKiosks/deleteKiosk', async (id, { dispatch, getState }) => {
  await axios.delete(`/terminal-delete/${id}`)
  await dispatch(getAllKiosks())
  await dispatch(getData(getState()))
  return id
})  

export const updateKiosk = createAsyncThunk('appKiosks/updateKiosk', async (kiosk, { dispatch, getState }) => {
  await axios.put(`/terminal-update/${kiosk.id}`, kiosk)
  await dispatch(getAllKiosks())
  await dispatch(getKiosk(kiosk.id))
  await dispatch(getData(getState()))
  return kiosk
})

export const appKiosksSlice = createSlice({
  name: 'appKiosks',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedKiosk: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllKiosks.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getKiosk.fulfilled, (state, action) => {
        state.selectedKiosk = action.payload
      })
  }
})

export default appKiosksSlice.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { paginateArray } from '@src/@fake-db/utils'
import { getAllPlayList, getData as getDataPlayList } from '../../playList/store'

export const getAllVideos = createAsyncThunk('appVideo/getAllVideos', async () => {
  const response = await axios.get('/qadmin/videos-list')
  return response.data
})

export const getData = createAsyncThunk('appVideo/getData', async (params) => {
  const { q = '', page = 1, perPage = 10, sort = 'asc', sortColumn = 'name', allData = [] } = params

  // /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const filteredData = allData.filter(
    videos => videos.name.toLowerCase().includes(queryLowered)
  )

  const dataAsc = filteredData.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const sendData = dataToFilter
  return {
    params,
    data: paginateArray(sendData, perPage, page),
    totalPages: sendData.length
  }
})

export const getVideo = createAsyncThunk('appVideo/getVideo', async (id) => {
  const response = await axios.get(`/qadmin/video/${id}`)
  return response.data
})

// export const addVideo = createAsyncThunk('appVideo/addVideo', async (video, { dispatch, getState }) => {
//   await axios.post('/qadmin/videos-add', video)
//   await dispatch(getAllVideos())
//   await dispatch(getData(getState()))
  
//   return video
// })

// export const updateVideo = createAsyncThunk('appVideo/updateVideo', async ({id, video}, { dispatch, getState }) => {
//   const config = {
//     headers: { 'Content-Type': 'multipart/form-data' }
//   }
//   await axios.put(`/qadmin/videos-update/${id}`, video, config)
//   await dispatch(getAllVideos())
//   await dispatch(getVideo(id))
//   await dispatch(getData(getState()))
//   return video
// })

export const deleteVideo = createAsyncThunk('appVideo/deleteVideo', async (id, { dispatch, getState }) => {
  await axios.delete(`/qadmin/videos-delete/${id}`)
  await dispatch(getAllVideos())
  await dispatch(getData(getState()))
  await dispatch(getDataPlayList(getState()))
  await dispatch(getAllPlayList())
  return id
})

export const appVideoSlice = createSlice({
  name: 'appVideo',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedVideo: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllVideos.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getVideo.fulfilled, (state, action) => {
        state.selectedVideo = action.payload
      })
  }
})


export default appVideoSlice.reducer

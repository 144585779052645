import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  services: [
    {
      id: 1,
      serviceName: 'Service Name 1',
      location: 'location 1',
      department: 'Department 1',
      owner: 'Manager',
      status: 'active',
      acronym: 'acronym',
      description: 'This is description 1 for service 1',
      subServices: [
        {
          id: 1,
          subServiceName: 'Service Name 1-1',
          status: 'active',
          acronym: 'acronym',
          subServices: [
            {
              id: 1,
              subServiceName: 'Service Name 1-1-1',
              status: 'active',
              acronym: 'acronym'
            },
            {
              id: 2,
              subServiceName: 'Service Name 1-1-2',
              status: 'unactive',
              acronym: 'acronym'
            },
            {
              id: 3,
              subServiceName: 'Service Name 1-1-3',
              status: 'active',
              acronym: 'acronym'
            }
          ]
        },
        {
          id: 2,
          subServiceName: 'Service Name 1-2',
          subServices: [],
          status: 'active',
          acronym: 'acronym'
        },
        {
          id: 3,
          subServiceName: 'Service Name 1-3',
          acronym: 'acronym',
          status: 'active',
          subServices: [
            {
              id: 1,
              subServiceName: 'Service Name 1-1-3',
              status: 'active',
              acronym: 'acronym'
            }
          ]
        }
      ],
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      id: 2,
      serviceName: 'Service Name 2',
      location: 'location 2',
      department: 'Department 2',
      owner: 'Manager',
      status: 'active',
      acronym: 'acronym',
      description: 'This is description 2 for service 2',
      subServices: [
        {
          id: 1,
          subServiceName: 'Service Name 2-2',
          subServices: [],
          status: 'unactive',
          acronym: 'acronym'
        },
        {
          id: 2,
          subServiceName: 'Service Name 2-1',
          acronym: 'acronym',
          status: 'active',
          subServices: [
            {
              id: 1,
              subServiceName: 'Service Name 2-1-1',
              status: 'active',
              acronym: 'acronym'
            }
          ]
        }
      ],
      avatar: require('@src/assets/images/avatars/6.png').default
    },
    {
      id: 3,
      serviceName: 'Service Name 3',
      location: 'location 3',
      department: 'Department 3',
      owner: 'Manager',
      status: 'active',
      acronym: 'acronym',
      description: 'This is description 3 for service 3',
      subServices: [
        {
          id: 1,
          subServiceName: 'Service Name 3-3',
          subServices: [],
          status: 'active',
          acronym: 'acronym'
        },
        {
          id: 3,
          subServiceName: 'Service Name 3-1',
          acronym: 'acronym',
          status: 'unactive',
          subServices: [
            {
              id: 1,
              subServiceName: 'Service Name 3-1-1',
              status: 'unactive',
              acronym: 'acronym'
            }
          ]
        }
      ],
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      id: 4,
      serviceName: 'Service Name 4',
      location: 'location 4',
      department: 'Department 4',
      owner: 'Manager',
      status: 'active',
      acronym: 'acronym',
      description: 'This is description 4 for service 4',
      subServices: [
        {
          id: 1,
          subServiceName: 'Service Name 4-4',
          subServices: [],
          status: 'active',
          acronym: 'acronym'
        },
        {
          id: 4,
          subServiceName: 'Service Name 4-1',
          acronym: 'acronym',
          status: 'active',
          subServices: [
            {
              id: 1,
              subServiceName: 'Service Name 4-1-1',
              status: 'active',
              acronym: 'acronym'
            }
          ]
        }
      ],
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      id: 5,
      serviceName: 'Service Name 5',
      location: 'location 5',
      department: 'Department 5',
      owner: 'Manager',
      status: 'active',
      acronym: 'acronym',
      description: 'This is description 5 for service 5',
      subServices: [
        {
          id: 1,
          subServiceName: 'Service Name 5-5',
          subServices: [],
          status: 'active',
          acronym: 'acronym'
        },
        {
          id: 5,
          subServiceName: 'Service Name 5-1',
          acronym: 'acronym',
          status: 'active',
          subServices: [
            {
              id: 1,
              subServiceName: 'Service Name 5-1-1',
              status: 'active',
              acronym: 'acronym'
            }
          ]
        }
      ],
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      id: 6,
      serviceName: 'Service Name 6',
      location: 'location 6',
      department: 'Department 6',
      owner: 'Manager',
      status: 'active',
      acronym: 'acronym',
      description: 'This is description 6 for service 6',
      subServices: [
        {
          id: 1,
          subServiceName: 'Service Name 6-6',
          subServices: [],
          status: 'active',
          acronym: 'acronym'
        },
        {
          id: 6,
          subServiceName: 'Service Name 6-1',
          acronym: 'acronym',
          status: 'active',
          subServices: [
            {
              id: 1,
              subServiceName: 'Service Name 6-1-1',
              status: 'active',
              acronym: 'acronym'
            }
          ]
        }
      ],
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      id: 7,
      serviceName: 'Service Name 7',
      location: 'location 7',
      department: 'Department 7',
      owner: 'Manager',
      status: 'active',
      acronym: 'acronym',
      description: 'This is description 7 for service 7',
      subServices: [
        {
          id: 1,
          subServiceName: 'Service Name 7-7',
          subServices: [],
          status: 'active',
          acronym: 'acronym'
        },
        {
          id: 7,
          subServiceName: 'Service Name 7-1',
          status: 'active',
          acronym: 'acronym',
          subServices: [
            {
              id: 1,
              subServiceName: 'Service Name 7-1-1',
              status: 'active',
              acronym: 'acronym'
            }
          ]
        }
      ],
      avatar: require('@src/assets/images/avatars/7.png').default
    },
    {
      id: 8,
      serviceName: 'Service Name 8',
      location: 'location 8',
      department: 'Department 8',
      owner: 'Manager',
      status: 'unactive',
      acronym: 'acronym',
      description: 'This is description 8 for service 8',
      subServices: [
        {
          id: 1,
          subServiceName: 'Service Name 8-8',
          subServices: [],
          status: 'active',
          acronym: 'acronym'
        },
        {
          id: 8,
          subServiceName: 'Service Name 8-1',
          acronym: 'acronym',
          status: 'active',
          subServices: [
            {
              id: 1,
              subServiceName: 'Service Name 8-1-1',
              status: 'active',
              acronym: 'acronym'
            }
          ]
        }
      ],
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      id: 9,
      serviceName: 'Service Name 9',
      location: 'location 9',
      department: 'Department 9',
      owner: 'Manager',
      status: 'active',
      acronym: 'acronym',
      description: 'This is description 9 for service 9',
      subServices: [
        {
          id: 1,
          subServiceName: 'Service Name 9-9',
          subServices: [],
          status: 'active',
          acronym: 'acronym'
        },
        {
          id: 9,
          subServiceName: 'Service Name 9-1',
          acronym: 'acronym',
          status: 'active',
          subServices: [
            {
              id: 1,
              subServiceName: 'Service Name 9-1-1',
              status: 'active',
              acronym: 'acronym'
            }
          ]
        }
      ],
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      id: 10,
      serviceName: 'Service Name 10',
      location: 'location 10',
      department: 'Department 10',
      owner: 'Manager',
      status: 'active',
      acronym: 'acronym',
      description: 'This is description 10 for service 10',
      subServices: [
        {
          id: 1,
          subServiceName: 'Service Name 10-10',
          subServices: [],
          status: 'active',
          acronym: 'acronym'
        },
        {
          id: 10,
          subServiceName: 'Service Name 10-1',
          acronym: 'acronym',
          status: 'active',
          subServices: [
            {
              id: 1,
              subServiceName: 'Service Name 10-1-1',
              status: 'active',
              acronym: 'acronym'
            }
          ]
        }
      ],
      avatar: require('@src/assets/images/avatars/10.png').default
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/services/list/all-data').reply(200, data.services)

// POST: Add new service
mock.onPost('/apps/services/add-service').reply((config) => {
  // Get event from post data
  const service = JSON.parse(config.data)
  const highestValue = data.services.reduce((a, b) => (a.id > b.id ? a : b)).id

  service.id = highestValue + 1

  data.services.push(service)

  return [201, { service }]
})

// GET Updated DATA
mock.onGet('/api/services/list/data').reply((config) => {
  const {
    q = '',
    page = 1,
    perPage = 10,
    sort = 'asc',
    sortColumn = 'fullName'
  } = config
  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.services.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    (service) =>
      ( service.serviceName.toLowerCase().includes(queryLowered) ||
        service.department.toLowerCase().includes(queryLowered) ||
        service.location.toLowerCase().includes(queryLowered)) 
  )
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      services: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET service
mock.onGet('/api/services/service').reply((config) => {
  const { id } = config
  const service = data.services.find((i) => i.id === id)
  return [200, { service }]
})

// DELETE: Deletes service
mock.onDelete('/apps/services/delete').reply((config) => {
  // Get service id from URL
  let serviceId = config.id

  // Convert Id to number
  serviceId = Number(serviceId)

  const serviceIndex = data.services.findIndex((t) => t.id === serviceId)
  data.services.splice(serviceIndex, 1)

  return [200]
})

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { paginateArray } from '@src/@fake-db/utils'

export const getAllPlayList = createAsyncThunk('appPlayList/getAllPlayList', async () => {
  const response = await axios.get('/qadmin/playlist-list')
  return response.data
})

export const getData = createAsyncThunk('appPlayList/getData', async (params) => {
  const { q = '', page = 1, perPage = 10, sort = 'asc', sortColumn = 'title', allData = [] } = params

  // /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const filteredData = allData.filter(
    playList => playList.titre.toLowerCase().includes(queryLowered)
  )

  const dataAsc = filteredData.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const sendData = dataToFilter
  return {
    params,
    data: paginateArray(sendData, perPage, page),
    totalPages: sendData.length
  }
})

export const getPlayList = createAsyncThunk('appPlayList/getPlayList', async (id) => {
  const response = await axios.get(`/qadmin/playlist/${id}`)
  return response.data
})

export const addPlayList = createAsyncThunk('appPlayList/addPlayList', async (playList, { dispatch, getState }) => {
  
  await axios.post('/qadmin/playlist-add', playList)
  await dispatch(getAllPlayList())
  await dispatch(getData(getState()))
  return playList
})

export const updatePlayList = createAsyncThunk('appPlayList/updatePlayList', async ({id, playList}, { dispatch, getState }) => {
  
  await axios.put(`/qadmin/playlist-update/${id}`, playList)
  await dispatch(getAllPlayList())
  await dispatch(getPlayList(id))
  await dispatch(getData(getState()))
  return playList
})

export const deletePlayList = createAsyncThunk('appPlayList/deletePlayList', async (id, { dispatch, getState }) => {
  await axios.delete(`/qadmin/playlist-delete/${id}`)
  await dispatch(getAllPlayList())
  await dispatch(getData(getState()))
  return id
})

export const appPlayListSlice = createSlice({
  name: 'appPlayList',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedPlaylist: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPlayList.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getPlayList.fulfilled, (state, action) => {
        state.selectedPlaylist = action.payload
      })
  }
})

export default appPlayListSlice.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { paginateArray } from '@src/@fake-db/utils'

export const getAllLocation = createAsyncThunk('appLocations/getAllLocation', async () => {
  const response = await axios.get('/qadmin/locations')
  return response.data
})

export const getData = createAsyncThunk('appLocation/getData', async (params) => {
  const { q = '', page = 1, perPage = 10, sort = 'asc', sortColumn = 'name', allData = [] } = params

  // /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const filteredData = allData.filter(
    location => location.name.toLowerCase().includes(queryLowered)
  )

  const dataAsc = filteredData.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const sendData = dataToFilter
  return {
    params,
    data: paginateArray(sendData, perPage, page),
    totalPages: sendData.length
  }
})

export const getLocation = createAsyncThunk('appLocation/getLocation', async (id) => {
  const response = await axios.get(`/qadmin/locations/${id}`)
  return response.data
})

export const addLocation = createAsyncThunk('appLocation/addLocation', async (location, { dispatch, getState }) => {
  await axios.post('/qadmin/location', location)
  await dispatch(getData(getState().locations.params))
  await dispatch(getAllLocation())
  return location
})

export const deleteLocation = createAsyncThunk('appLocation/deleteLocation', async (id, { dispatch, getState }) => {
  await axios.delete(`/qadmin/locations/${id}`)
  await dispatch(getData(getState().locations.params))
  await dispatch(getAllLocation())
  return id
})
 
export const updateLocation = createAsyncThunk('appLocation/updateLocation', async ({id, location}, { dispatch, getState }) => {
  await axios.put(`/qadmin/locations/${id}`, location)
  await dispatch(getData(getState().locations.params))
  await dispatch(getLocation(id))
  await dispatch(getAllLocation())
  return location
})

export const getAllCountry = createAsyncThunk('appAllCountry/getAllCountry', async ()=>{
  const response = await axios.get('/qadmin/countries/')
  return response.data
})

export const appLocationsSlice = createSlice({
  name: 'appLocations',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [], 
    selectedLocation: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllLocation.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getLocation.fulfilled, (state, action) => {
        state.selectedLocation = action.payload
      })
      .addCase(getAllCountry.fulfilled, (state, action) => {
        state.allCountry = action.payload
      })
  }
})

export default appLocationsSlice.reducer

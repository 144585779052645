import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  tvGroups: [
    {
      id: 1,
      services: [
        require('@src/assets/images/avatars/2.png').default,
        require('@src/assets/images/avatars/5.png').default
      ],
      location: 'Location 1',
      numberOfMembers: 2,
      streamUrl: 'Rollei-Traveler-Noir/a16934671...'
    },
    {
      id: 2,
      services: [
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/9.png').default,
        require('@src/assets/images/avatars/10.png').default
      ],
      location: 'Location 2',
      numberOfMembers: 3,
      streamUrl: 'Rollei-Traveler-Noir/a16934671...'
    },
    {
      id: 3,
      services: [
        require('@src/assets/images/avatars/1.png').default,
        require('@src/assets/images/avatars/2.png').default,
        require('@src/assets/images/avatars/3.png').default,
        require('@src/assets/images/avatars/4.png').default
      ],
      location: 'Location 3',
      numberOfMembers: 4,
      streamUrl: 'Rollei-Traveler-Noir/a16934671...'
    },
    {
      id: 4,
      services: [require('@src/assets/images/avatars/7.png').default],
      location: 'Location 4',
      numberOfMembers: 1,
      streamUrl: 'Rollei-Traveler-Noir/a16934671...'
    },
    {
      id: 5,
      services: [
        require('@src/assets/images/avatars/9.png').default,
        require('@src/assets/images/avatars/4.png').default,
        require('@src/assets/images/avatars/10.png').default,
        require('@src/assets/images/avatars/7.png').default,
        require('@src/assets/images/avatars/8.png').default
      ],
      location: 'Location 5',
      numberOfMembers: 5,
      streamUrl: 'Rollei-Traveler-Noir/a16934671...'
    }    
  ]
}

// GET ALL DATA
mock.onGet('/api/tvGroups/list/all-data').reply(200, data.tvGroups)

// POST: Add new tvGroup
mock.onPost('/apps/tvGroups/add-tvGroup').reply((config) => {
  // Get event from post data
  const tvGroup = JSON.parse(config.data)
  const highestValue = data.tvGroups.reduce((a, b) => (a.id > b.id ? a : b)).id

  tvGroup.id = highestValue + 1

  data.tvGroups.push(tvGroup)

  return [201, { tvGroup }]
})

// GET Updated DATA
mock.onGet('/api/tvGroups/list/data').reply((config) => {
  const {
    q = '',
    page = 1,
    perPage = 10,
    sort = 'asc',
    sortColumn = 'fullName'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.tvGroups.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()
  const filteredData = dataToFilter.filter(
    (tvGroup) =>
      (
        tvGroup.service.toLowerCase().includes(queryLowered) ||
        tvGroup.streamUrl.toLowerCase().includes(queryLowered) ||
        tvGroup.location.toLowerCase().includes(queryLowered) 
        ))
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      tvGroups: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET tvGroup
mock.onGet('/api/tvGroups/tvGroup').reply((config) => {
  const { id } = config
  const tvGroup = data.tvGroups.find((i) => i.id === id)
  return [200, { tvGroup }]
})

// DELETE: Deletes tvGroup
mock.onDelete('/apps/tvGroups/delete').reply((config) => {
  // Get tvGroup id from URL
  let tvGroupId = config.id

  // Convert Id to number
  tvGroupId = Number(tvGroupId)

  const tvGroupIndex = data.tvGroups.findIndex((t) => t.id === tvGroupId)
  data.tvGroups.splice(tvGroupIndex, 1)

  return [200]
})

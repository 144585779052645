import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  tvs: [
    {
      id: 1,
      serialNumber: '111111111',
      group: 'Group 1',
      brand: 'Brand 1',
      model: 'Model 1',
      ip_address: '192.0.21',
      mac_address: '00:26:57:00:1f:02',
      location: 'Location 1',
      status: 'active'
    },

    {
      id: 2,
      serialNumber: '222222222',
      group: 'Group 2',
      brand: 'Brand 2',
      model: 'Model 2',
      ip_address: '192.0.22',
      mac_address: '00:26:57:00:2f:02',
      location: 'Location 2',
      status: 'unactive'
    },

    {
      id: 3,
      serialNumber: '333333333',
      group: 'Group 3',
      brand: 'Brand 3',
      model: 'Model 3',
      ip_address: '192.0.21',
      mac_address: '00:26:57:00:1f:02',
      location: 'Location 3',
      status: 'active'
    },

    {
      id: 4,
      serialNumber: '444444444',
      group: 'Group 4',
      brand: 'Brand 4',
      model: 'Model 4',
      ip_address: '192.0.21',
      mac_address: '00:26:57:00:1f:02',
      location: 'Location 4',
      status: 'active'
    },

    {
      id: 5,
      serialNumber: '555555555',
      group: 'Group 5',
      brand: 'Brand 5',
      model: 'Model 5',
      ip_address: '192.0.21',
      mac_address: '00:26:57:00:1f:02',
      location: 'Location 5',
      status: 'unactive'
    },

    {
      id: 6,
      serialNumber: '666666666',
      group: 'Group 2',
      brand: 'Brand 6',
      model: 'Model 6',
      ip_address: '192.0.21',
      mac_address: '00:26:57:00:1f:02',
      location: 'Location 6',
      status: 'active'
    },

    {
      id: 7,
      serialNumber: '777777777',
      group: 'Group 5',
      brand: 'Brand 7',
      model: 'Model 7',
      ip_address: '192.0.21',
      mac_address: '00:26:57:00:1f:02',
      location: 'Location 7',
      status: 'active'
    },

    {
      id: 8,
      serialNumber: '888888888',
      group: 'Group 4',
      brand: 'Brand 8',
      model: 'Model 8',
      ip_address: '192.0.21',
      mac_address: '00:26:57:00:1f:02',
      location: 'Location 8',
      status: 'active'
    },

    {
      id: 9,
      serialNumber: '999999999',
      group: 'Group 1',
      brand: 'Brand 9',
      model: 'Model 9',
      ip_address: '192.0.21',
      mac_address: '00:26:57:00:1f:02',
      location: 'Location 9',
      status: 'unactive'
    },

    {
      id: 10,
      serialNumber: '1010101010',
      group: 'Group 2',
      brand: 'Brand 10',
      model: 'Model 10',
      ip_address: '192.0.21',
      mac_address: '00:26:57:00:1f:02',
      location: 'Location 10',
      status: 'active'
    }
    
  ]
}

// GET ALL DATA
mock.onGet('/api/tvs/list/all-data').reply(200, data.tvs)

// POST: Add new tv
mock.onPost('/apps/tvs/add-tv').reply((config) => {
  // Get event from post data
  const tv = JSON.parse(config.data)
  const highestValue = data.tvs.reduce((a, b) => (a.id > b.id ? a : b)).id

  tv.id = highestValue + 1

  data.tvs.push(tv)

  return [201, { tv }]
})

// GET Updated DATA
mock.onGet('/api/tvs/list/data').reply((config) => {
  const {
    q = '',
    page = 1,
    perPage = 10,
    sort = 'asc',
    sortColumn = 'fullName'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.tvs.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()
  const filteredData = dataToFilter.filter(
    (tv) =>
      (
        tv.serialNumber.toLowerCase().includes(queryLowered) ||
        tv.brand.toLowerCase().includes(queryLowered) ||
        tv.model.toLowerCase().includes(queryLowered) ||
        tv.location.toLowerCase().includes(queryLowered) 
        ))
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      tvs: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET tv
mock.onGet('/api/tvs/tv').reply((config) => {
  const { id } = config
  const tv = data.tvs.find((i) => i.id === id)
  return [200, { tv }]
})

// DELETE: Deletes tv
mock.onDelete('/apps/tvs/delete').reply((config) => {
  // Get tv id from URL
  let tvId = config.id

  // Convert Id to number
  tvId = Number(tvId)

  const tvIndex = data.tvs.findIndex((t) => t.id === tvId)
  data.tvs.splice(tvIndex, 1)

  return [200]
})

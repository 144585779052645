import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  locations: [
    {
      id: 1,
      location:'BNP Paribas - Neuilly Sablons Parmentier',
      city:'Paris',
      numberOfDesk: '12'
    },
    {
      id: 2,
      location:'location 2',
      city:'Paris',
      numberOfDesk: '12'
    },
    {
      id: 3,
      location:'BNP Paribas - Neuilly Sablons Parmentier',
      city:'Paris',
      numberOfDesk: '12'
    },
    {
      id: 4,
      location:'BNP Paribas - Neuilly Sablons Parmentier',
      city:'Paris',
      numberOfDesk: '12'
    },
    {
      id: 5,
      location:'BNP Paribas - Neuilly Sablons Parmentier',
      city:'Paris',
      numberOfDesk: '12'
    },
    {
      id: 6,
      location:'BNP Paribas - Neuilly Sablons Parmentier',
      city:'Paris',
      numberOfDesk: '12'
    },
    {
      id: 7,
      location:'BNP Paribas - Neuilly Sablons Parmentier',
      city:'Paris',
      numberOfDesk: '12'
    },
    {
      id: 8,
      location:'BNP Paribas - Neuilly Sablons Parmentier',
      city:'Paris',
      numberOfDesk: '12'
    },
    {
      id: 9,
      location:'BNP Paribas - Neuilly Sablons Parmentier',
      city:'Paris',
      numberOfDesk: '12'
    },
    {
      id: 10,
      location:'BNP Paribas - Neuilly Sablons Parmentier',
      city:'Paris',
      numberOfDesk: '12'
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/locations/list/all-data').reply(200, data.locations)

// POST: Add new staff
mock.onPost('/apps/locations/add-location').reply((config) => {
  // Get event from post data
  const location = JSON.parse(config.data)
  const highestValue = data.locations.reduce((a, b) => (a.id > b.id ? a : b)).id

  location.id = highestValue + 1

  data.locations.push(location)

  return [201, { location }]
})

// GET Updated DATA
mock.onGet('/api/locations/list/data').reply((config) => {
  const {
    q = '',
    page = 1,
    perPage = 10,
    sort = 'asc',
    numberOfDesk = null,
    services = null,
    sortColumn = 'location'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.locations.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    (location) =>
      (location.location.toLowerCase().includes(queryLowered) ||
        location.city.toLowerCase().includes(queryLowered) ||
        location.date.toLowerCase().includes(queryLowered)) &&
        location.numberOfDesk === (numberOfDesk || location.numberOfDesk)
  )
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      locations: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET location
mock.onGet('/api/locations/location').reply((config) => {
  const { id } = config
  const location = data.locations.find((i) => i.id === id)
  return [200, { location }]
})

// DELETE: Deletes location
mock.onDelete('/apps/locations/delete').reply((config) => {
  // Get location id from URL
  let locationId = config.id

  // Convert Id to number
  locationId = Number(locationId)

  const locationIndex = data.locations.findIndex((t) => t.id === locationId)
  data.locations.splice(locationIndex, 1)

  return [200]
})
